<template>
  <div>
    <h3>Order #{{ $route.params.id }}</h3>
    <div class="card" v-if="!loading">
      <div class="card-body">
        <div class="d-flex">
          <div>
            <img :src="order.org.logo" alt="" />
          </div>
          <div class="ps-3 centered">
            <div>
              <h4>{{ order.org.name }}</h4>
              <h6>Total Credits: {{ order.credits }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      order: {},
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/dashboard/orders/${this.$route.params.id}`)
        .then((response) => {
          this.order = response.data.order;
          this.loading = false;
        });
    },
  },
};
</script>
