<template>
  <div>
    <h3>My Orders</h3>
    <div v-if="!loading">
      <div class="card mb-2" v-for="(order, i) in orders" :key="`order-${i}`">
        <div class="card-body">
          <router-link :to="{ name: 'dashboard.orders.show', params: { id: order.id }}" class="btn btn-primary float-end btn-sm"><i class="fa fa-th me-2"></i> Details</router-link>
          <h4>{{ order.org }}</h4>
          <h5 class="text-muted">Credits: {{ order.credits }}</h5>
        </div>
      </div>
      <alert type="info" v-if="orders.length == 0"
        >There is no data to display.</alert
      >
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      orders: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/dashboard/orders").then((response) => {
        this.orders = response.data.orders;
        this.loading = false
      });
    },
  },
};
</script>
